import React from 'react';
import Navigation from '../components/Navigation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Contact = () => {
    return (
        <div className="contact">
           <Navigation/> 
           <div className="contactContent">
               <div className="header"></div>
               <div className="contactBox">
                   <h1> Contactez-moi</h1>
                   <ul>
                       <li>
                           <i className="fas fa-map-marker-alt"></i>
                           <span>Bruxelles</span>
                       </li>
                       <li>
                           <i className="fas fa-envelope"></i>
                           <CopyToClipboard text="hicheme.bengaied@outlook.fr">
                               <span className="clickInput" onClick={() => {alert('E-mail copié !');}
                                }>
                                    hicheme.bengaied@outlook.fr
                               </span>
                           </CopyToClipboard>
                       </li>
                   </ul>
               </div>
               <div className="socialNetwork">
                   <ul>
                        <a href="https://www.facebook.com/hicheme.bengaied" target="_blank" rel="noopener noreferrer">
                                <h4>Facebook</h4>
                                <i className="fab fa-facebook"></i>
                        </a>
                        <a href="https://www.instagram.com/hichemebengaied/?hl=fr" target="_blank" rel="noopener noreferrer">
                            <h4>Instagram</h4>
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://github.com/hbengaied" target="_blank" rel="noopener noreferrer">
                            <h4>GitHub</h4>
                            <i className="fab fa-github"></i>
                        </a>
                   </ul>
               </div>
           </div>
        </div>
    );
};

export default Contact;