import React from 'react';

const OtherSkills = () => {
    return (
        <div className="otherSkills">
            <h3>Autres compétences</h3>
            <div className="list">
                <ul>
                    <li><i className="fas fa-check-square"></i>Visual Studio</li>
                    <li><i className="fas fa-check-square"></i>Visual Studio Code</li>
                    <li><i className="fas fa-check-square"></i>Github</li>
                    <li><i className="fas fa-check-square"></i>Méthodes agiles</li>
                </ul>
                <ul>
                    <li><i className="fas fa-check-square"></i>Boost.Test </li>
                    <li><i className="fas fa-check-square"></i>Figma</li>
                    <li><i className="fas fa-check-square"></i>Anglais courant</li>
                    <li><i className="fas fa-check-square"></i>Arabe courant</li>
                </ul>
            </div>
        </div>
    );
};

export default OtherSkills;