export const portfolioData = [
  {
    id: 1,
    name: 'Risking',
    languages: ['javascript','css','react','php','c++','sql'],
    languagesIcons: ['fab fa-js','fab fa-css3-alt','fab fa-react','fab fa-php','fab fa-database','fab fa-cuttlefish'],
    source: 'https://github.com/hbengaied/Risking',
    info: " Jeu en ligne sur le web nommé Risking. Objectif : conquérir les territoires voisins avec des troupes de soldats. Travail en groupe de dix personnes. Pour ma part j'ai du coder une partie du serveur, lobby, et des règles du jeu ainsi que l'écriture des tests . J'ai également aisé au développement des interfaces, accueil, inscription, modale et paramètre du jeu.",
    picture: './media/risking.png'
  },
  {
    id: 2,
    name: 'Supervisation bancaire',
    languages: ['c++'],
    languagesIcons: ['fab fa-cuttlefish'],
    source: 'https://github.com/hbengaied/IHM-BANQUE',
    info: " Application pour la visualisation des différents canaux et produits vendus par différentes agences d’une banque. Cette application permet d’avoir les volumes vendus, chiffres d’affaires, et pourcentages réalisés par chaque employé.",
    picture: './media/ihm.png'
  },
  {
    id: 3,
    name: 'Système de localisation',
    languages: ['c#'],
    languagesIcons: [],
    source: 'https://github.com/hbengaied/Systeme_Location_C-',
    info: "Projet consistant à localiser des colis disposés en intérieur et d'afficher les colis dans l'ordre le plus proche afin de les récupérer rapidement. Ce projet a été réalisé avec la technologie UWB.",
    picture: './media/localisation.png'
  },
  {
    id: 4,
    name: 'Snake Game',
    languages: ['c#'],
    languagesIcons: ['fab fa-js','fab fa-php','fab fa-react'],
    source: 'https://github.com/hbengaied/SnakeGame',
    info: "Création d'un projet snake permettant de jouer seul ou bien en multijoueur en créant son propre serveur. La partie en ligne permet aux joueurs de jouer une partie en même temps et de faire un classement des joueurs en fonction du temps de leur survie.",
    picture: './media/snake.png'
  },
  {
    id: 5,
    name: 'Mon site personnel',
    languages: ['javascript','sass','react'],
    languagesIcons: ['fab fa-js', 'fab fa-sass', 'fab fa-react'],
    source: 'http://www.github.com',
    info: 'Création de mon site personnel.',
    picture: './media/project5.JPG'
  },
  {
    id: 6,
    name: 'Base de données site de préférences vidéos',
    languages: ['sql'],
    languagesIcons: ['fab fa-database'],
    source: 'https://github.com/hbengaied/BDD-Videos',
    info: "Création d'une base de données pour un site de préférences vidéos from scratch.",
    picture: './media/bdd.jpg'
  },
  {
    id: 7,
    name: 'Projet programmation parallèle',
    languages: ['c'],
    languagesIcons: ['fab fa-cuttlefish'],
    source: 'https://github.com/hbengaied/ProgParallele',
    info: "Ce projet avait pour but d'optimiser et de rendre un code plus rapide issue d'un google hash code en utilisant la programmation parallèle en C. Projet réalisé en binôme.",
    picture: './media/pp.jpg'
  },
]