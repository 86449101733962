import React from 'react';

const Experience = () => {
    return (
        <div className="experience">
            <h3>Expérience</h3>
            <div className="exp-1">
                <h4>Application de supervision bancaire</h4>
                <h5>02/2021 - 04/2021</h5>
                <p>Application pour la visualisation des différents canaux et produits vendus par différentes agences
                d’une banque. Cette application permet d’avoir les volumes vendus, chiffres d’affaires, et
                pourcentages réalisés par chaque employé.
                <br/>
                <br/>
                <br/>
                </p>
            </div>

            <div className="exp-2">
                <h4>Jeu de conquête en ligne : https://risking.zefresk.com/</h4>
                <h5>01/21 - 06/2021</h5>
                <p>Jeu en ligne sur le web nommé Risking. Objectif : Conquérir les territoires voisins avec des troupes de soldats.
                </p>
            </div>

            <div className="exp-3">
                <h4>Système de localisation de colis</h4>
                <h5>01/22 - 05/2022</h5>
                <p>
                    Ce projet consiste à localiser les colis afin de pouvoir les récupérer tout en indiquant le colis le plus proche à récupérer.
                     Ce projet a été mené à bien en utilisant la technologie UWB.
                </p>
            </div>
            <div className="exp-4">
                <h4>Snake Game</h4>
                <h5>03/22 - 05/2022</h5>
                <p>
                    Création du jeu snake ! Possibilité de jouer en solo ou bien en ligne.
                </p>
            </div>
            <div className="exp-(">
                <h4>Diplômes</h4>
                <h5>2021</h5>
                <p>
                Bac +3 de licence d’informatique et mathématique, Université de Strasbourg, France.
                </p>
                <h5>2021-Actuellement</h5>
                <p>Bac +3 Ingénieur Industriel en Informatique, HE2B – Unité structurelle ISIB Bruxelles, Belgique.</p>
            </div>
        </div>
    );
};

export default Experience;