import React, { Component } from 'react'
import ProgressBar from './ProgressBar';

export default class Languages extends Component {
  state = {
    languages : [
      {id : 1, value :"C", xp:5},
      {id : 3, value :"Javascript", xp:4},
      {id : 8, value :"SQL", xp:4},
      {id : 5, value :"Java", xp:3.5},
      {id : 2, value :"C++", xp:3},
      {id : 4, value :"Css", xp:3},
      {id : 7, value :"Php", xp:2.7},
      {id : 6, value :"Python", xp:2.3},
      {id : 8, value :"C#", xp:1}
    ],
    frameworks : [
      {id : 1, value :"React", xp:2},
      {id : 2, value :"Qt", xp:2},
      {id : 3, value :"Windows Form .NET", xp:1}
    ]

  }
  render() {
    let {languages,frameworks} = this.state;
    return (
      <div className="languagesFrameworks">
        <ProgressBar
          languages = {languages}
          className ="languagesDisplay"
          title="languages"
        />
        <ProgressBar
          languages = {frameworks}
          className ="frameworksDisplay"
          title="frameworks & bibliothèques"
        />
      </div>
    )
  }
}
